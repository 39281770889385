































import { useLocations } from "@/lib/locations";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    locationId: { type: Number }
  },
  setup(props, { emit }) {
    const form = ref();
    const { byId, remove } = useLocations();
    const location = computed(() => byId(props.locationId));
    const cancel = () => emit("hideDelete");
    const submit = () => {
      if (!form.value.validate()) return;
      remove(location.value.value.id);
      cancel();
    };
    return { location, remove, cancel, submit, form };
  }
});
