import { Actions, Getters, States } from "@/store/modules/locations/types";
import { Modules } from "@/store/types";
import { CrudItem } from "@/types/crud";
import { ContactType } from "@/types/locations/contact";
import { Location, LocationType } from "@/types/locations/location";
import { useStore } from "../useStore";

const { useActions, useState, useGetters } = useStore(Modules.LOCATIONS);

const useLocationActions = () => {
  const {
    [Actions.GET]: get,
    [Actions.CREATE]: create,
    [Actions.SAVE]: save,
    [Actions.REMOVE]: remove,
    [Actions.CREATE_CONTACT]: createContact,
    [Actions.SET_CONTACT]: setContact
  } = useActions([
    Actions.GET,
    Actions.CREATE,
    Actions.SAVE,
    Actions.REMOVE,
    Actions.CREATE_CONTACT,
    Actions.SET_CONTACT
  ]);
  return {
    get,
    create,
    save,
    remove,
    createContact,
    setContact
  };
};

const useLocationStates = () => {
  const { [States.LOCATIONS]: locations } = useState([States.LOCATIONS]);
  return { locations };
};

const useLocationGetters = () => {
  const {
    [Getters.BY_ID]: byId,
    [Getters.GET_CONTACT]: getContact,
    [Getters.ALL]: getAll,
    [Getters.LIST]: list
  } = useGetters([
    Getters.BY_ID,
    Getters.GET_CONTACT,
    Getters.ALL,
    Getters.LIST
  ]);
  return { byId, getContact, getAll, list };
};

const useLocationEnums = () => {
  const locationTypes = Object.values(LocationType).filter(Boolean);
  const contactTypes = Object.values(ContactType).filter(Boolean);
  return { locationTypes, contactTypes, LocationType, ContactType };
};

const useFilters = () => {
  const filterByType = (
    locations: CrudItem<Location>[],
    ...types: (LocationType | undefined)[]
  ) => {
    return (locations || []).filter(({ value }) => types.includes(value.type));
  };
  return { filterByType };
};

export const useLocations = () => ({
  ...useLocationActions(),
  ...useLocationStates(),
  ...useLocationGetters(),
  ...useLocationEnums(),
  ...useFilters()
});
