































import { useLocations } from "@/lib/locations";
import { config as contactTypeConfig } from "@/types/locations/contact";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    locationId: { type: Number }
  },
  setup(props) {
    const { createContact, contactTypes, byId } = useLocations();
    const contactType = ref<string>();
    const form = ref();
    const location = computed(() => byId(props.locationId));
    const add = async () => {
      if (!contactType.value) return;
      await createContact({
        locationId: props.locationId,
        contact: { type: contactType.value }
      });
      contactType.value = undefined;
    };
    return {
      contactType,
      form,
      add,
      contactTypes,
      location,
      contactTypeConfig
    };
  }
});
