

































import { useLocations } from "@/lib/locations";
import { config as typeConfig, LocationType } from "@/types/locations/location";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    types: {
      type: Array as PropType<LocationType[]>,
      default: Object.values(LocationType)
    },
    showTypeInTitle: { type: Boolean }
  },
  setup({ types: _types }) {
    const form = ref();
    const { create } = useLocations();
    const type = computed(() => (_types?.length === 1 ? _types[0] : undefined));
    const submit = () => form.value.validate() && create({ type: type.value });
    return { form, submit, type, typeConfig };
  }
});
