























































































































import { useLocations } from "@/lib/locations";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import LocationCreateContact from "./CreateContact.vue";
import LocationContact from "./Contact.vue";
import { LocationType } from "@/types/vimbApi/erlaubnisinhaber/location";
import { config as locationConfig } from "@/types/locations/location";

export type FieldsConfig = {
  title?: boolean;
  type?: boolean;
  name?: boolean;
  street?: boolean;
  houseNumber?: boolean;
  co?: boolean;
  zip?: boolean;
  city?: boolean;
  state?: boolean;
  country?: boolean;
  contacts?: boolean;
};

const fields: FieldsConfig = {
  type: false,
  street: true,
  houseNumber: true,
  zip: true,
  city: true
};

export default defineComponent({
  props: {
    types: {
      type: Array as PropType<LocationType[]>,
      default: () => Object.values(LocationType)
    },
    locationId: { type: Number },
    fields: { type: Object as PropType<FieldsConfig> }
  },
  components: { LocationContact, LocationCreateContact },
  setup(props) {
    const form = ref();
    const { byId, save, contactTypes } = useLocations();
    const location = computed(() => byId(props.locationId));
    const fieldsConfig = computed(() => ({ ...fields, ...props.fields }));
    const submit = () => {
      if (!form.value.validate()) return;
      save(location.value.value);
    };
    return {
      location,
      fieldsConfig,
      contactTypes,
      form,
      submit,
      locationConfig
    };
  }
});
