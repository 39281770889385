
















































import { useLocations } from "@/lib/locations";
import { config as contactTypeConfig } from "@/types/locations/contact";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    locationId: { type: Number },
    contactId: { type: Number }
  },
  setup(props) {
    const { getContact, setContact, byId } = useLocations();
    const contact = computed(() =>
      getContact(props.locationId, props.contactId)
    );
    const location = computed(() => byId(props.locationId));
    const deleteContact = (remove = true) =>
      setContact({
        locationId: props.locationId,
        contact: { ...contact.value, deleted: remove }
      });
    return { contact, deleteContact, location, contactTypeConfig };
  }
});
