


























import { useLocations } from "@/lib/locations";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

import LocationEmpty from "./Empty.vue";
import LocationForm, { FieldsConfig } from "./Form.vue";
import LocationDelete from "./Delete.vue";
import { LocationType } from "@/types/locations/location";

export default defineComponent({
  props: {
    locationId: { type: Number },
    fields: { type: Object as PropType<FieldsConfig> },
    types: {
      type: Array as PropType<LocationType[]>,
      default: () => Object.values(LocationType)
    }
  },
  components: { LocationEmpty, LocationForm, LocationDelete },
  setup(props) {
    const { byId } = useLocations();
    const location = computed(() => byId(props.locationId));
    const showRemove = ref<boolean>(false);
    return { location, showRemove };
  }
});
